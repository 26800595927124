<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g class="a">
      <rect class="c" width="18" height="18" rx="4" />
      <rect class="d" x="1" y="1" width="16" height="16" rx="3" />
    </g>
    <g transform="translate(-985 -4282)">
      <rect
        class="b"
        width="18"
        height="18"
        rx="4"
        transform="translate(991 4288)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTemplate",
};
</script>

<style lang="scss" scoped>
.a,
.d {
  fill: none;
}
.a {
  stroke: #2d323c;
  stroke-linecap: round;
  stroke-width: 2px;
}
.b {
  fill: #2d323c;
}
.c {
  stroke: none;
}
</style>