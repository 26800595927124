<template>
  <svg
    :class="color"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <circle class="a" cx="12" cy="12" r="12" />
    <path
      class="b"
      d="M21388.973,3034.836h12"
      transform="translate(-21382.973 -3022.836)"
    />
    <path class="b" d="M0,0H12" transform="translate(12 18) rotate(-90)" />
  </svg>
</template>

<script>
export default {
  props: ["color"],
  name: "Add",
};
</script>

<style lang="scss" scoped>
.strom {
  .a {
    fill: $strom;
  }
}
.midnight {
  .a {
    fill: $midnight;
  }
}

.grass {
  .a {
    fill: #00cc99;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.a {
  transition: all ease 0.3s;
  fill: #b40068;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>