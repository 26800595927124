<template>
  <main class="home">
    <section class="progress">
      <p class="stage">3</p>
      <div class="bar"><span></span></div>
      <p class="max">3</p>
    </section>
    <section class="header">
      <h1>Event details</h1>
      <p>Add and set key details about your events (required)</p>
    </section>
    <div
      class="wrapper"
      :id="'event' + eventIndex"
      v-for="(event, eventIndex) in tour.events"
      :key="eventIndex"
      :class="{ error: erronousSections.includes('event' + eventIndex) }"
    >
      <section
        class="event-header"
        :id="'event' + (eventIndex + 1)"
        @click="
          $store.dispatch('toggleEventSectionExpansion', {
            property: 'expanded',
            value: !event.utility.expanded,
            eventIndex,
          })
        "
      >
        <h4>
          {{ formattedEventName(event, eventIndex) }}
        </h4>

        <Chevron2 :direction="event.utility.expanded ? 'up' : 'down'" />
      </section>
      <slide-up-down :active="event.utility.expanded" :duration="500">
        <section class="details" :key="'e' + eventIndex">
          <div>
            <span>
              <h5>Event name</h5>
              <DefaultTextInput
                :value="event.name"
                @change="updateName($event, eventIndex)"
                :maxlength="50"
                placeholder="Add event name"
              />
              <h5 class="location">Event location</h5>
              <div class="set-location" v-if="event.city != null">
                {{ event.city.name + ", " + event.city.country }}
                <svg
                  @click="removeCityFromEvent(eventIndex)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="remove-button"
                >
                  <g transform="translate(-320 -377)">
                    <g transform="translate(320 377)">
                      <path
                        class="a"
                        d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
                      />
                    </g>
                    <line class="b" x2="10" transform="translate(327 389)" />
                  </g>
                </svg>
              </div>
              <div
                class="find-location"
                v-else
                :class="{ expanded: locationSearchResults.length > 0 }"
              >
                <input
                  type="text"
                  autocomplete="new-password"
                  maxlength="50"
                  placeholder="Add event city and country"
                  v-if="event.city == null"
                  v-model="locationSearch"
                  @blur="locationSearch = ''"
                  @keydown="debouncedCitySearch()"
                />
                <transition-group tag="ul" name="slide-fade">
                  <li
                    v-for="location in locationSearchResults"
                    :key="location.id"
                    @click.stop="selectCity(location, eventIndex)"
                  >
                    {{ location.name }}, {{ location.country }}
                  </li>
                </transition-group>
              </div>
            </span>
            <span>
              <div class="rounds">
                <h5>Rounds</h5>
                <div
                  class="item"
                  v-for="n in 8"
                  :key="n"
                  :class="{ active: n == event.rounds.length }"
                  @click="
                    triggerRoundAdjustmentModal(
                      eventIndex,
                      event.rounds.length,
                      n
                    )
                  "
                >
                  {{ n }}
                </div>
              </div>
              <div class="pools-selector">
                <h5>Pools</h5>
                <div
                  class="item"
                  v-for="n in 5"
                  :key="n"
                  :class="{
                    none: n == 1,
                    active: event.rounds[0].pools.length == n,
                  }"
                  @click="
                    triggerPoolAdjustmentModal(
                      eventIndex,
                      event.rounds[0].pools.length,
                      n
                    )
                  "
                >
                  <template v-if="n == 1"> None </template>
                  <template v-else>
                    {{ n }}
                  </template>
                </div>
              </div>
            </span>
          </div>
        </section>

        <template v-for="(round, roundIndex) in event.rounds">
          <section
            class="pools"
            v-for="(pool, poolIndex) in round.pools"
            :key="roundIndex + 'p' + poolIndex"
          >
            <div
              class="wrapper"
              :class="{ error: erronousSections.includes('error' + poolIndex) }"
            >
              <header v-if="round.pools.length == 1">
                <h4>Round {{ roundIndex + 1 }}</h4>
              </header>
              <header v-else>
                <h4>
                  Round {{ roundIndex + 1 }} - Pool
                  {{ $store.state.poolConversion[poolIndex] }}
                </h4>
              </header>
              <div class="startwhenwrapper">
                <div class="starting-format">
                  <h5>Starting format</h5>
                  <DefaultRadioButton
                    :groupname="
                      eventIndex +
                      'event' +
                      roundIndex +
                      'starting-format' +
                      poolIndex
                    "
                    value="SHOTGUN"
                    :options="{ title: 'Shotgun', value: pool.startMethod }"
                    @change="
                      $store.dispatch('updatePoolStartMethod', {
                        eventIndex: eventIndex,
                        roundIndex: roundIndex,
                        poolIndex: poolIndex,
                        startMethod: 'SHOTGUN',
                      })
                    "
                  />
                  <DefaultRadioButton
                    :groupname="
                      eventIndex +
                      'event' +
                      roundIndex +
                      'starting-format' +
                      poolIndex
                    "
                    value="TEETIME"
                    :options="{
                      title: 'Teetime',
                      value: pool.startMethod,
                    }"
                    @change="
                      $store.dispatch('updatePoolStartMethod', {
                        eventIndex: eventIndex,
                        roundIndex: roundIndex,
                        poolIndex: poolIndex,
                        startMethod: 'TEETIME',
                      })
                    "
                  />
                </div>
                <div class="when">
                  <h5>When</h5>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="poolDateTimeOptions"
                    :dateTime="pool.date"
                    @update="
                      updatePoolDateTime(
                        eventIndex,
                        roundIndex,
                        poolIndex,
                        $event
                      )
                    "
                  />
                </div>
              </div>
              <div class="where">
                <h5>Where</h5>
                <StandardSmallSolidButton
                  v-if="pool.layout !== null"
                  class="small-button"
                  :fluid="true"
                  @click="
                    toggleVisibleCourseSelectors(
                      'selector' +
                        'e' +
                        eventIndex +
                        'r' +
                        roundIndex +
                        'p' +
                        poolIndex
                    )
                  "
                  title="Edit"
                />
                <TheBuilderLayoutPicker
                  :pool="pool"
                  :edit="
                    pool.layout == null ||
                    visibleCourseSelectors.includes(
                      'selector' +
                        'e' +
                        eventIndex +
                        'r' +
                        roundIndex +
                        'p' +
                        poolIndex
                    )
                  "
                  @apply-layout="
                    applyLayout($event, eventIndex, roundIndex, poolIndex)
                  "
                />
              </div>
            </div>
          </section>
        </template>
        <div
          class="settings-header"
          @click="
            $store.dispatch('toggleEventSectionExpansion', {
              property: 'settingsExpanded',
              value: !event.utility.settingsExpanded,
              eventIndex,
            })
          "
        >
          <h4>Settings <span>(optional)</span></h4>

          <Chevron2
            :direction="event.utility.settingsExpanded ? 'up' : 'down'"
          />
        </div>
        <slide-up-down :active="event.utility.settingsExpanded" :duration="500">
          <section class="misc-settings">
            <div class="max-players">
              <h5>Max players</h5>
              <ValueSelector
                :values="maxSpotsOptions(event).values"
                :colors="maxSpotsOptions(event).colors"
                :title="''"
                @updatedValue="
                  $store.dispatch('updateEventStringValue', {
                    value: $event.value,
                    property: 'maxSpots',
                    eventIndex: eventIndex,
                  })
                "
              />
            </div>
            <div class="group-size">
              <h5>Group size</h5>
              <div
                class="item"
                v-for="n in 5"
                :key="n"
                :class="{ active: n == event.groupSize }"
                @click="
                  $store.dispatch('updateEventStringValue', {
                    value: n,
                    property: 'groupSize',
                    eventIndex: eventIndex,
                  })
                "
              >
                {{ n }}
              </div>
            </div>
            <div class="wildcards">
              <h5>Wildcards</h5>

              <ValueSelector
                :values="wildcardOptions(event).values"
                :colors="wildcardOptions(event).colors"
                :title="''"
                @updatedValue="
                  $store.dispatch('updateEventStringValue', {
                    value: $event.value,
                    property: 'maxWildcards',
                    eventIndex: eventIndex,
                  })
                "
              />
            </div>
            <div class="registration-open">
              <div class="sub-section left">
                <h5>Registration opening date</h5>
                <TjingDateTimePicker
                  class="datepicker"
                  :canBeCleared="true"
                  :readOnly="false"
                  :options="registrationOpensTime(event)"
                  :dateTime="event.registrationOpenAt"
                  @update="
                    updateEventTimeField(
                      $event,
                      eventIndex,
                      'registrationOpenAt'
                    )
                  "
                />
              </div>
              <div class="sub-section auto-open">
                <div class="header">
                  <h6>Automatically open</h6>
                  <DefaultToggle
                    :value="event.autoOpenRegistration"
                    :disabled="event.registrationOpenAt == null"
                    @input="
                      $store.dispatch('updateBoolOnEvent', {
                        property: 'autoOpenRegistration',
                        eventIndex: eventIndex,
                        value: $event,
                      })
                    "
                  />
                </div>

                <p>
                  When enabled Tjing will open registration for you based on the
                  registration opening date you’ve set.
                </p>
              </div>
            </div>
            <div class="waitinglist-type">
              <h5>Waiting list type</h5>
              <div class="content">
                <DefaultRadioButton
                  class="mb-4"
                  groupname="waitinglist-type"
                  :value="event.waitinglistType"
                  @change="changeWaitinglistType(eventIndex, 'DIVISION')"
                  :options="{
                    title: 'Separated divisions',
                    value: 'DIVISION',
                    description: 'Each division has its own waiting list.',
                  }"
                />
                <DefaultRadioButton
                  groupname="waitinglist-type"
                  :value="event.waitinglistType"
                  @change="changeWaitinglistType(eventIndex, 'COMBINED')"
                  :options="{
                    title: 'Combined divisions',
                    value: 'COMBINED',
                    description:
                      'All players and divisions are placed in one waiting list.',
                  }"
                />
              </div>
            </div>
            <div
              class="check-in"
              :class="{
                error: erronousSections.includes('check-in'),
                expanded: event.utility.checkInEnabled,
              }"
            >
              <div
                class="header"
                @click="
                  toggleCheckInEnabled(
                    eventIndex,
                    !event.utility.checkInEnabled
                  )
                "
              >
                <h5>Check in</h5>
                <span
                  ><Chevron2
                    :direction="event.utility.checkInEnabled ? 'up' : 'down'"
                /></span>
              </div>
              <slide-up-down
                :active="event.utility.checkInEnabled"
                :duration="300"
              >
                <div class="open">
                  <h6>Opens</h6>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="checkInStartsTime(event)"
                    :dateTime="event.checkInStartsAt"
                    @update="
                      updateEventTimeField(
                        $event,
                        eventIndex,
                        'checkInStartsAt'
                      )
                    "
                  />
                </div>
                <div class="closes">
                  <h6>Closes</h6>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="checkInEndsTime(event)"
                    :dateTime="event.checkInEndsAt"
                    @update="
                      updateEventTimeField($event, eventIndex, 'checkInEndsAt')
                    "
                  />
                </div>
              </slide-up-down>
            </div>

            <div
              class="pdga-tier"
              :class="{
                expanded: event.utility.pdgaEnabled,
              }"
            >
              <div class="header">
                <h5>PDGA sanctioned</h5>
                <DefaultToggle
                  :value="event.utility.pdgaEnabled"
                  @input="togglePDGASanctioned(eventIndex, $event)"
                />
              </div>
              <slide-up-down
                :active="event.utility.pdgaEnabled"
                :duration="300"
                class="radios"
              >
                <DefaultRadioButton
                  v-for="type in pdgaEventTypes"
                  :key="type.type"
                  :groupname="'pdgatier'"
                  :value="event.pdgaType"
                  :options="{
                    title: type.name,
                    value: type.type,
                  }"
                  @change="
                    $store.dispatch('updateEventPDGAType', {
                      eventIndex: eventIndex,
                      pdgaType: type.type,
                    })
                  "
                />
              </slide-up-down>
            </div>
          </section>
        </slide-up-down>
        <div
          class="contact-details-header"
          @click="
            $store.dispatch('toggleEventSectionExpansion', {
              property: 'contactDetailsExpanded',
              value: !event.utility.contactDetailsExpanded,
              eventIndex,
            })
          "
        >
          <h4>Contact details <span>(optional)</span></h4>

          <Chevron2
            :direction="event.utility.contactDetailsExpanded ? 'up' : 'down'"
          />
        </div>
        <slide-up-down
          :active="event.utility.contactDetailsExpanded"
          :duration="500"
        >
          <div class="copy-paste" v-if="tour.events.length > 1">
            <div>
              <p>
                <CopyPaste />
                Choose to copy contact details from another event and paste them
                to this event.
              </p>
              <span>
                <b>1. Choose the event you would like to copy from</b>
                <select
                  v-model="pasteFrom"
                  :class="{ disabled: pasteFrom == null }"
                >
                  <option :value="null">Choose event</option>
                  <option
                    :value="subEventIndex"
                    :disabled="subEventIndex == eventIndex"
                    v-for="(event, subEventIndex) in tour.events"
                    :key="subEventIndex"
                  >
                    {{ formattedEventName(event, subEventIndex) }}
                  </option>
                </select>
              </span>
              <span>
                <b>2. Paste to this event</b>
                <StandardSolidButton
                  title="Paste"
                  :disabled="pasteFrom == null"
                  :fluid="false"
                  @click="pasteContactDetails(eventIndex)"
                />
              </span>
            </div>
          </div>
          <div class="tournament-director">
            <h4>Tournament Director</h4>
            <DefaultTextInput
              :value="event.tournamentDirectorName"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'tournamentDirectorName',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD name"
              title="Tour Director name"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.email"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'email',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD email"
              title="Tournament Director email"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.phone"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'phone',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD phone"
              title="Tour Director phone"
              :fat="true"
            />
          </div>
          <div class="tournament-director">
            <h4>Assistant Tournament Director</h4>

            <DefaultTextInput
              :value="event.assistantTournamentDirectorName"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorName',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD name"
              title="Assistant Tour Director name"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.assistantTournamentDirectorEmail"
              @input="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorEmail',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD email"
              title="Assistant Tour Director email"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.assistantTournamentDirectorPhone"
              @input="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorPhone',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD phone"
              title="Assistant Tour Director phone"
              :fat="true"
            />
          </div>
          <div class="general">
            <h4>General details</h4>
            <DefaultTextInput
              :value="event.website"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'website',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add event website"
              title="Event website"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.pdgaEventId"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'pdgaEventId',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Paste PDGA event ID"
              title="PDGA event ID"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.location"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'location',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add event address"
              title="Event address"
              :fat="true"
            />
            <h6>Additional details</h6>
            <textarea
              maxlength="1500"
              v-model.lazy="event.description"
              placeholder="Add additional details"
            />
          </div>
        </slide-up-down>
      </slide-up-down>
      <section class="event-control">
        <div>
          <div
            class="delete"
            @click="deleteEventTrigger(eventIndex)"
            v-if="tour.events.length > 1"
          >
            Delete event
          </div>
          <div v-else></div>
          <div class="duplicate" @click="duplicateEvent(event)">
            <Duplicate />Duplicate event
          </div>
        </div>
      </section>
    </div>

    <div class="add-event" @click="addBlankEvent()">
      <Add />
      <p><b>Add another event</b></p>
    </div>

    <section class="nav-buttons">
      <div class="tip">
        <span><Tip /></span>

        <p>
          Everything can be edited in the Manager after your tour has been
          saved.
        </p>
      </div>
      <StandardBorderedButton
        title="Back"
        :fluid="false"
        :backwardsIcon="true"
        :desktopFluidity="true"
        @click="$router.go(-1)"
      />
      <StandardSolidButton
        class="forwards"
        title="Save tour"
        :fluid="false"
        :desktopFluidity="true"
        @click="validateAndProceed()"
      />
    </section>
    <section class="start-over" @click="$refs.resetmodal.open()">
      Reset and start over
    </section>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removepoolsmodal"
      hide-close-button
    >
      <h3>Remove pools</h3>
      <p>Are you sure you want to remove pools?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removepoolsmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfPools()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removeroundsmodal"
      hide-close-button
    >
      <h3>Remove rounds</h3>
      <p>Are you sure you want to remove rounds?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removeroundsmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfRounds()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="savemodal"
      hide-close-button
    >
      <h3>That's it!</h3>
      <p>Your tour has been saved and is now in the Manager.</p>
      <div class="buttons">
        <StandardBorderedButton
          title="Build more"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
        <StandardSolidButton
          title="Manager"
          :fluid="false"
          @click="toTheManager()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="saveerrormodal"
      hide-close-button
    >
      <h3>Ohh no....</h3>
      <p>
        Something went wrong...
        <br />
        <br />
        But we have exported a file you can send to our support for help
        troubleshooting what went wrong!
      </p>

      <StandardSolidButton
        class="mt-5"
        title="Close"
        :fluid="false"
        @click="downloadlog()"
      />
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="deleteeventmodal"
      hide-close-button
    >
      <h3>Delete event</h3>
      <p>Are you sure you want to delete this event?</p>
      <div class="buttons">
        <StandardSolidButton
          title="Delete"
          :fluid="false"
          @click="deleteEvent()"
        />
        <StandardBorderedButton
          title="Cancel"
          :fluid="false"
          @click="$refs.deleteeventmodal.close()"
        />
      </div>
    </sweet-modal>
  </main>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import ValueSelector from "@/components/ValueSelector";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import TheBuilderLayoutPicker from "@/components/builder/TheBuilderLayoutPicker";
import Tip from "@/assets/icons/Tip";
import Add from "@/assets/icons/Add";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import CopyPaste from "@/assets/icons/CopyPaste";
import Duplicate from "@/assets/icons/Duplicate";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";

export default {
  name: "TournamentEventDetails",
  components: {
    Tip,
    StandardSolidButton,
    StandardBorderedButton,
    DefaultRadioButton,
    TjingDateTimePicker,
    DefaultToggle,
    ValueSelector,
    DefaultTextInput,
    TheBuilderLayoutPicker,
    StandardSmallSolidButton,
    Chevron2,
    SlideUpDown,
    CopyPaste,
    Duplicate,
    Add,
  },
  data() {
    return {
      eventToDelete: null,
      pasteFrom: null,
      visibleCourseSelectors: [],
      locationSearch: "",
      locationSearchResults: [],
      erronousSections: [],
      roundAdjustmentValues: null,
      poolAdjustmentValues: null,
      tourSaved: false,
      createdTourData: null,
    };
  },

  computed: {
    poolDateTimeOptions() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    validCheckInTimes() {
      let valid = true;

      this.tour.events.forEach((event) => {
        if (event.checkInStartsAt == null && event.checkInEndsAt != null)
          valid = false;
        if (event.checkInEndsAt == null && event.checkInStartsAt != null)
          valid = false;
      });

      return valid;
    },
    pdgaEventTypes() {
      return this.$store.getters.tour.pdgaEventTypes;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
    tourSettings() {
      return this.$store.getters.tour.tourSettings;
    },
  },
  methods: {
    changeWaitinglistType(eventIndex, value) {
      this.$store.dispatch("changeWaitinglistType", {
        eventIndex,
        value,
      });
    },
    registrationOpensTime(event) {
      let time = moment().add(10, "years");

      event.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (moment(pool.date).isBefore(time)) {
            time = moment(pool.date);
          }
        });
      });

      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: time,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    checkInStartsTime(event) {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: event.checkInEndsAt,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    checkInEndsTime(event) {
      return {
        minDateTime: event.checkInStartsAt,
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    downloadlog() {
      this.download(this.tour.name, JSON.stringify(this.createdEventData));
      this.$refs.saveerrormodal.close();
    },
    triggerPoolAdjustmentModal(eventIndex, pools, newVal) {
      this.poolAdjustmentValues = { eventIndex, pools, newVal };

      if (pools > 1 && newVal < pools) {
        this.$refs.removepoolsmodal.open();
      } else {
        this.adjustNumberOfPools();
      }
    },
    triggerRoundAdjustmentModal(eventIndex, rounds, newVal) {
      this.roundAdjustmentValues = { eventIndex, rounds, newVal };
      if (rounds > 1 && newVal < rounds) {
        this.$refs.removeroundsmodal.open();
      } else {
        this.adjustNumberOfRounds();
      }
    },
    deleteEventTrigger(eventIndex) {
      this.eventToDelete = eventIndex;
      this.$refs.deleteeventmodal.open();
    },
    toTheManager() {
      this.$refs.savemodal.close();
      this.$nextTick(() => {
        this.$router.push({ name: "manage" });
      });
    },
    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async saveTour() {
      let cleanedDivisions = [];
      let cleanedEvents = [];

      this.tour.events.forEach((event) => {
        let clonedEvent = JSON.parse(JSON.stringify(event));

        clonedEvent.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            pool.layoutVersionId = pool.layout.latestVersion.id;
            delete pool.layout;
          });
        });

        clonedEvent.isVerifyMode = this.tourSettings.verifier;
        clonedEvent.isResultSigningEnabled = this.tourSettings.signature;

        clonedEvent.cityId = clonedEvent.city.id;
        delete clonedEvent.city;
        delete clonedEvent.utility;

        cleanedEvents.push(clonedEvent);
      });

      this.tour.divisions.forEach((division) => {
        let cleanedDivision = {
          type: division.type,
          currencyCode: division.currency,
        };

        if (division.reservedSpots != null && division.reservedSpots > 0) {
          cleanedDivision.reservedSpots = division.reservedSpots;
        }

        if (division.maxSpots != null && division.maxSpots > 0) {
          cleanedDivision.maxSpots = division.maxSpots;
        }
        if (
          division.registrationFee.value != null &&
          division.registrationFee.value != ""
        ) {
          cleanedDivision.registrationFee = division.registrationFee.value;
        } else {
          cleanedDivision.registrationFee = 0;
        }
        if (
          division.tourPassFee != null &&
          this.tour.connectedAccountId != null
        ) {
          if (division.tourPassFee.value != "") {
            cleanedDivision.tourPassFee = division.tourPassFee.value;
          }
        }

        cleanedDivisions.push(cleanedDivision);
      });

      let CreateTourExtended = {
        name: this.tour.name,
        scoringType: this.tour.scoringType,
        registrationMethod: this.tour.registrationMethod,
        allowRegistrationMessage: this.tour.allowRegistrationMessage,
        type: this.tour.type == null ? null : this.tour.type.type,
        events: cleanedEvents,
        divisions: cleanedDivisions,
      };

      if (
        this.tour.scoreBasedOnNrOfEvents &&
        this.tour.scoreBasedOnNrOfEvents > 1
      ) {
        CreateTourExtended.scoreBasedOnNrOfEvents =
          this.tour.scoreBasedOnNrOfEvents;
      }

      if (this.tour.connectedAccountId != null) {
        CreateTourExtended.connectedAccountId = this.tour.connectedAccountId;
        CreateTourExtended.paymentType = "MANAGED";
      }

      try {
        let createdTour = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation CreateTourExtended($CreateTourExtended: ExtendedTourInput!){
            CreateTourExtended(input:$CreateTourExtended){
              id
            }
          }
          `,
            variables: {
              CreateTourExtended,
            },
          },
        });

        if (createdTour.data.errors) {
          this.createdTourData = createdTour.data;
          this.$refs.saveerrormodal.open();
          CreateTourExtended.error = createdTour.data;
        } else {
          this.$refs.savemodal.open();
          this.tourSaved = true;
        }
      } catch (err) {
        this.createdTourData = createdTour.data;
        this.$refs.saveerrormodal.open();
      }
    },
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },
    toggleCheckInEnabled(eventIndex, value) {
      this.$store.dispatch("toggleCheckInEnabled", {
        eventIndex,
        value,
      });

      this.removeError("event" + eventIndex);
    },
    togglePDGASanctioned(eventIndex, value) {
      this.$store.dispatch("togglePDGASanctioned", {
        eventIndex,
        value,
      });
    },
    updatePoolDateTime(eventIndex, roundIndex, poolIndex, dateTime) {
      this.$store.dispatch("updatePoolStartTime", {
        eventIndex: eventIndex,
        roundIndex: roundIndex,
        poolIndex: poolIndex,
        date: dateTime,
      });

      this.removeError("event" + eventIndex);
    },
    addBlankEvent() {
      this.$store.dispatch("addBlankEventToTour");
      this.$store.dispatch("showMessage", {
        show: true,
        message: "New event added",
        type: "success",
      });
      this.$nextTick(() => {
        this.$scrollTo("#" + ["event" + this.tour.events.length]);
      });
    },
    pasteContactDetails(eventIndex) {
      this.$store.dispatch("pasteContactDetails", {
        targetEventIndex: eventIndex,
        fromEventIndex: this.pasteFrom,
      });
      this.pasteFrom = null;
      this.$store.dispatch("showMessage", {
        show: true,
        message: "Pasted",
        type: "success",
      });
    },
    formattedEventName(event, eventIndex) {
      if (event.name == null || event.name == "") {
        return `Event (${eventIndex + 1}) `;
      } else {
        return `${event.name} (${eventIndex + 1})`;
      }
    },
    deleteEvent() {
      this.$store.dispatch("deleteEvent", this.eventToDelete);
      this.eventToDelete = null;
      this.$refs.deleteeventmodal.close();
    },
    duplicateEvent(event) {
      this.$store.dispatch("duplicateEvent", JSON.parse(JSON.stringify(event)));

      this.$nextTick(() => {
        this.$scrollTo("#" + ["event" + this.tour.events.length]);
      });
    },
    removeError(error) {
      this.erronousSections = this.erronousSections.filter((e) => e != error);
    },
    updateName(data, eventIndex) {
      this.$store.dispatch("updateEventName", {
        name: data,
        eventIndex,
      });

      this.removeError("event" + eventIndex);
    },
    removeCityFromEvent(eventIndex) {
      this.$store.dispatch("removeCityFromEvent", eventIndex);
    },
    citySearch() {
      this.$axios({
        data: {
          query: `
          {
            citySearch(query:"${this.locationSearch}"){
              id
              name
              country
              county
              geolocation{
                lng
                lat
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          if (this.locationSearch != "") {
            this.locationSearchResults = result.data.data.citySearch;
          }
        })
        .catch(() => {});
    },
    debouncedCitySearch: debounce(
      function () {
        this.citySearch();
      },
      1000,
      { maxWait: 1200 }
    ),
    selectCity(location, eventIndex) {
      this.$store.dispatch("applyCityToEvent", {
        city: location,
        eventIndex: eventIndex,
      });
      this.locationSearch = "";
      this.locationSearchResults = [];

      this.removeError("event" + eventIndex);
    },
    toggleVisibleCourseSelectors(id) {
      if (this.visibleCourseSelectors.includes(id)) {
        this.visibleCourseSelectors = this.visibleCourseSelectors.filter(
          (selectors) => selectors != id
        );
      } else {
        this.visibleCourseSelectors.push(id);
      }
    },
    applyLayout(layout, eventIndex, roundIndex, poolIndex) {
      this.$store.dispatch("applyLayoutToPool", {
        layout: layout,
        eventIndex: eventIndex,
        roundIndex: roundIndex,
        poolIndex: poolIndex,
      });
      this.visibleCourseSelectors = [];

      this.removeError("event" + eventIndex);
    },
    adjustNumberOfPools() {
      let eventIndex = this.poolAdjustmentValues.eventIndex;
      let currentNumberOfPools = this.poolAdjustmentValues.pools;
      let newNumberOfPools = this.poolAdjustmentValues.newVal;
      if (currentNumberOfPools > newNumberOfPools) {
        let roundsToRemove = currentNumberOfPools - newNumberOfPools;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removePoolFromRounds", { eventIndex });
        }

        this.$refs.removepoolsmodal.close();
      } else if (newNumberOfPools > currentNumberOfPools) {
        let roundsToAdd = newNumberOfPools - currentNumberOfPools;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addPoolToRounds", { eventIndex });
        }
      }
    },
    adjustNumberOfRounds() {
      let eventIndex = this.roundAdjustmentValues.eventIndex;
      let currentNumberOfRounds = this.roundAdjustmentValues.rounds;
      let newNumberOfRounds = this.roundAdjustmentValues.newVal;

      if (currentNumberOfRounds > newNumberOfRounds) {
        let roundsToRemove = currentNumberOfRounds - newNumberOfRounds;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removeRoundFromEvent", { eventIndex });
        }

        this.$refs.removeroundsmodal.close();
      } else if (newNumberOfRounds > currentNumberOfRounds) {
        let roundsToAdd = newNumberOfRounds - currentNumberOfRounds;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addRoundToEvent", { eventIndex });
        }
      }
    },
    markErrors() {
      this.tour.events.forEach((event, eventIndex) => {
        let errorFound = false;
        if (event.name == null || event.name.length < 3) {
          this.erronousSections.push("event" + eventIndex);
          errorFound = true;
        }
        if (event.city == null) {
          this.erronousSections.push("event" + eventIndex);
          errorFound = true;
        }
        if (
          (event.checkInStartsAt == null && event.checkInEndsAt !== null) ||
          (event.checkInStartsAt !== null && event.checkInEndsAt == null)
        ) {
          this.erronousSections.push("event" + eventIndex);
          errorFound = true;
        }

        event.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            if (pool.date == null || pool.layout == null) {
              this.erronousSections.push("event" + eventIndex);
              errorFound = true;
            }
          });
        });

        if (errorFound) {
          this.$store.dispatch("toggleEventSectionExpansion", {
            property: "expanded",
            value: true,
            eventIndex,
          });
        }
      });
    },
    validateAndProceed() {
      this.markErrors();

      if (this.erronousSections.length == 0) {
        this.saveTour();
      } else {
        this.$scrollTo("#" + this.erronousSections[0]);
      }
    },
    maxSpotsOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 999; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxSpots || (event.maxSpots == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    wildcardOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 20; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxWildcards ||
            (event.maxWildcards == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    updateEventTimeField(date, eventIndex, property) {
      if (property == "registrationOpenAt" && date == null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: false,
        });
      }
      if (property == "registrationOpenAt" && date != null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: true,
        });
      }
      this.$store.dispatch("updateEventTimeField", {
        eventIndex: eventIndex,
        property: property,
        date: moment(date).toISOString(),
      });
      this.removeError("event" + eventIndex);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {
    if (this.tourSaved) {
      this.$store.dispatch("resetBuilderState");
    }
  },
};
</script>

<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}
.add-event {
  cursor: pointer;
  margin: 24px 12px 0 12px;
  display: flex;
  height: 48px;
  padding: 0 20px;
  align-items: center;
  border-radius: 6px;
  background: $fog;
  transition: all ease 0.3s;

  p {
    margin: 0;
  }
  svg {
    margin-right: 10px;
  }
}

.event-control {
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  > div {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
    padding: 20px;
  }

  .duplicate {
    @include Gilroy-Bold;
    display: flex;
    align-items: center;
    font-size: 14px;

    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .delete {
    color: $dusk;
    font-size: 14px;
    cursor: pointer;
    &.disabled {
      color: $sleet;
      cursor: default;
    }
  }
}
.general {
  background-color: white;
  padding: 24px 15px 0px 15px;

  h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }
  .payment-text {
    margin-top: 10px;
  }

  h6 {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 1.8em;
  }

  textarea {
    resize: vertical;
    width: 100%;
    height: 250px;
    min-height: 250px;
    border: 1px solid $blizzard;
    transition: all ease 0.3s;
    border-radius: 6px;
    padding: 15px;
    margin-top: 10px;

    &::placeholder {
      color: $blizzard;
      @include Gilroy-Medium;
    }

    &:hover {
      border: 1px solid $midnight;
    }
  }
}
.copy-paste {
  background: white;
  > div {
    padding: 20px 20px 28px 20px;
    p {
      margin-bottom: 24px;
      svg {
        width: 18px;
        height: 18px;
        margin-bottom: 4px;
        margin-right: 8px;
      }
    }

    p,
    b {
      font-size: 14px;
    }
    b {
      @include Gilroy-Bold;
    }
    select,
    button {
      margin-top: 12px;
    }
    select {
      option {
        color: $midnight;
        @include Gilroy-Bold;
        &:disabled {
          color: $blizzard;
        }
      }

      &.disabled {
        color: $blizzard !important;
        @include Gilroy-Regular;
      }
    }
  }
}
.tournament-director {
  background-color: white;
  padding: 24px 15px 32px 15px;
  border-bottom: 1px solid $fog;

  h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }
}
.details {
  span {
    width: 100%;
  }
  > div {
    border-top-left-radius: 6px;

    background: white;
    padding: 20px 20px 28px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    transition: all ease 0.3s;

    h5 {
      font-size: 16px;
      margin-bottom: 12px;

      span {
        color: $blizzard;
      }

      &.location {
        margin-top: 24px;
      }
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 24px;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
  a {
    font-size: 14px;
    color: $strom;
    margin-top: 8px;
  }

  .rounds,
  .pools-selector {
    width: 100%;

    h5 {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 24px;
      width: 100%;
    }
    p {
      font-size: 18px;
      width: 100%;
      text-align: left;
      margin-top: 24px;
      @include Gilroy-Bold;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blizzard;
      color: $blizzard;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 10px;
      @include Gilroy-Bold;
      transition: all ease 0.3s;

      &.active {
        border: 1px solid $twilight;
        background: $twilight;
        color: white;

        &:hover {
          color: white;
          cursor: default;
          border: 1px solid $twilight;
        }
      }

      &:hover {
        border: 1px solid $midnight;
        color: $midnight;
        cursor: pointer;
      }

      &.none {
        width: 64px;
      }
    }
  }

  .default-toggle {
    margin-top: 16px;
  }
  .feature-icon {
    height: 48px;
    width: auto;
  }
}
.registration-type {
  margin-top: 24px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  background: white;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
  }
  .default-radio-buttons {
    margin-top: 16px;
  }
}

.settings-header,
.contact-details-header {
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background-color: $mist;
  margin-top: 0px;
  border-top: 1px solid white;
  h4 {
    font-size: 18px;
    margin: 0;
    span {
      @include Gilroy-Regular;
    }
  }
}

.misc-settings {
  padding: 20px 20px 28px 20px;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
  }
}
.wildcards,
.max-players {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .selector-container {
    margin-top: 12px;
  }
}
.wildcards,
.group-size {
  margin-top: 24px;
  padding-top: 22px;
  border-top: 1px solid $fog;
}
.group-size {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  p {
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin-top: 24px;
    @include Gilroy-Bold;
    margin-bottom: 0;
  }

  .item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blizzard;
    color: $blizzard;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 12px;
    @include Gilroy-Bold;
    transition: all ease 0.3s;
    font-size: 16px;

    &.active {
      border: 1px solid $twilight;
      background: $twilight;
      color: white;

      &:hover {
        color: white;
        cursor: default;
        border: 1px solid $twilight;
      }
    }

    &:hover {
      border: 1px solid $midnight;
      color: $midnight;
      cursor: pointer;
    }

    &.none {
      width: 64px;
    }
  }
}
.registration-open {
  display: flex;
  flex-direction: column;
  background: white;
  border-top: 1px solid $fog;
  margin-top: 24px;
  padding: 20px 0px 0px 0px;

  h5 {
    font-size: 16px;
    margin: 0;
  }

  .datepicker {
    margin-top: 12px;
  }

  .auto-open {
    margin-top: 24px;

    .header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        font-size: 14px;
        margin: 0;
      }

      h5 {
        font-size: 16px;
        @include Gilroy-Bold;
        margin: 0;
      }
    }
    p {
      margin-top: 14px;
      font-size: 14px;
    }
  }
}
.waitinglist-type {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  margin-top: 16px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  border-radius: 6px;
  width: 100%;

  .content {
    margin-top: 12px;
  }
}
.check-in {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  margin-top: 16px;
  padding: 20px 0px 28px 0px;
  border-top: 1px solid $fog;
  border-radius: 6px;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-direction: row;
    cursor: pointer;
  }
  > div {
    width: 100%;
    margin-top: 0px;
  }

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }

  h6 {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h5 {
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
  }
  .closes {
    margin-top: 8px;
  }
}
.pdga-tier {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  width: 100%;
  .radios {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .default-radio-buttons {
      width: 50%;
      margin-top: 16px;

      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-direction: row;
  }

  h5 {
    margin-bottom: 0;
  }
}
.nav-buttons {
  margin: 22px 12px 0 12px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}

.wrapper {
  margin: 0 12px;
  margin-top: 40px;
  box-shadow: 0 0 0 1px $sleet;
  border-radius: 6px;
  transition: all ease 0.3s;

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }

  .event-header {
    background: $fog;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    padding: 0 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    h4 {
      font-size: 18px;
      @include Gilroy-Bold;
      margin: 0;
    }
  }
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 100%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
}
.set-location {
  border: 1px solid $blizzard;
  border-radius: 6px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &:hover {
    border: 1px solid $midnight;
  }

  .remove-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .a {
      fill: $dusk;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
}
.find-location {
  width: 100%;
  border: 1px solid $blizzard;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &.expanded {
    padding-bottom: 20px;
    transition: all ease 0.3s;
  }

  &:hover {
    border: 1px solid $midnight;
  }

  input {
    height: 46px;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    transition: all ease 0.3s;

    &:focus {
      border: 0px solid transparent;
      transition: all ease 0.3s;
    }

    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transition: all 0.3s ease;
    max-height: 0;
    padding: 0px 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    width: 100%;
    li {
      cursor: pointer;
      transition: all ease 0.3s;
      max-height: 50px;
      overflow: hidden;
      padding: 5px 15px;
      transition: all ease 0.3s;
      width: 100%;
      display: flex;

      &:hover {
        background-color: $mist;
        transition: all ease 0.3s;
      }
    }
  }
}
.find-location {
  width: 100%;
  border: 1px solid $blizzard;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &.expanded {
    padding-bottom: 20px;
    transition: all ease 0.3s;
  }

  &:hover {
    border: 1px solid $midnight;
  }

  input {
    height: 46px;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    transition: all ease 0.3s;

    &:focus {
      border: 0px solid transparent;
      transition: all ease 0.3s;
    }

    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transition: all 0.3s ease;
    max-height: 0;
    padding: 0px 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    width: 100%;
    li {
      cursor: pointer;
      transition: all ease 0.3s;
      max-height: 50px;
      overflow: hidden;
      padding: 5px 15px;
      transition: all ease 0.3s;
      width: 100%;
      display: flex;

      &:hover {
        background-color: $mist;
        transition: all ease 0.3s;
      }
    }
  }
}

.pools {
  .wrapper {
    margin: 0;
    box-shadow: none;
  }

  > div {
    background: white;
    border-radius: 0;

    &.error {
      box-shadow: 0 0 0 2px $dusk;
    }

    header {
      @include Gilroy-Bold;
      padding: 9px 12px;
      background: $mist;

      h4 {
        font-size: 18px;
        margin: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin-bottom: 0;

      span {
        @include Gilroy-Regular;
        color: $strom;
        font-size: 12px;
      }
    }

    .when {
      border-bottom: 1px solid $fog;
      margin-left: 20px;
      margin-right: 20px;
      padding-bottom: 24px;
      padding-top: 20px;

      h5 {
        margin-bottom: 12px;
      }
    }
    .starting-format {
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid $fog;
      padding-bottom: 24px;

      display: flex;
      flex-wrap: wrap;
      h5 {
        width: 100%;
        margin-bottom: 14px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        > div {
          margin-left: 8px;
        }
      }
      .default-radio-buttons {
        width: 50%;
        display: inline;
      }
    }
    .where {
      position: relative;
      margin: 24px 20px 0px 20px;
      padding-bottom: 28px;

      h5 {
        margin-bottom: 0px;
      }
      .small-button {
        position: absolute;
        right: 15px;
        top: -2px;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .progress {
    justify-content: center;
    .bar {
      width: 50%;
    }
  }
  .add-event {
    cursor: pointer;
    margin: 32px 12px 0 12px;
    display: flex;
    height: 48px;
    padding: 0 20px;
    align-items: center;
    border-radius: 6px;
    background: $fog;
    transition: all ease 0.3s;
  }
}

@media (min-width: 1200px) {
  .waitinglist-type {
    margin-top: 0px;
    border-radius: 10px;
    padding: 40px 48px 40px 40px;
    h5 {
      font-size: 20px;
    }

    .content {
      margin-top: 24px;
    }
  }
  .check-in {
    padding: 40px 40px 40px 40px;
    border-radius: 0;

    &.expanded {
      padding-bottom: 48px;
    }
    .header {
      h5 {
        font-size: 20px;
      }
      svg {
        width: 21px;
        height: 12px;
      }
    }
    > div {
      display: flex;
      justify-content: space-between;
      .open,
      .closes {
        width: 47%;
        margin-top: 32px;

        h6 {
          font-size: 16px;
        }
      }
    }
  }

  .start-over {
    font-size: 16px;
  }
  .copy-paste {
    > div {
      padding: 40px 48px;
      display: flex;
      flex-wrap: wrap;
      select {
        margin: 0;
        margin-top: 16px;
      }
      p {
        width: 100%;
        font-size: 16px;
        margin-bottom: 32px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
      span {
        margin-right: 72px;
        b {
          font-size: 16px;
        }
        button {
          margin-top: 16px;
        }
      }
    }
  }
  .event-control {
    > div {
      height: 96px;
      padding: 0 48px;
    }
    .duplicate {
      padding: 12px 20px;
      border-radius: 10px;
      font-size: 16px;
      &:hover {
        background: $mist;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .delete {
      font-size: 16px;
    }
  }
  .settings-header,
  .contact-details-header {
    height: 48px;
    padding: 0 24px;
    background-color: $mist;

    h4 {
      font-size: 20px;
      margin: 0;
    }
    svg {
      cursor: pointer;
      width: 21px;
      height: 12px;
    }
  }
  .pools {
    > div {
      header {
        height: 48px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        width: 100%;

        h4 {
          font-size: 20px;
          width: 100%;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      border-radius: 0px;

      .startwhenwrapper {
        width: 40%;

        .starting-format {
          padding-right: 48px;
          padding-left: 48px;
          margin: 0;
          padding-bottom: 42px;
          h5 {
            font-size: 20px;
            padding-top: 42px;
            margin-top: 0;
            margin-bottom: 24px;
          }
        }

        .when {
          padding-top: 42px;
          padding-right: 48px;
          padding-left: 48px;
          border-bottom: none;
          margin: 0 0 32px 0;

          h5 {
            font-size: 20px;
            margin-bottom: 24px;
          }
        }
      }

      .where {
        width: 60%;
        margin: 0;
        padding-left: 48px;
        border-left: 1px solid $fog;
        padding-right: 48px;

        .small-button {
          top: 38px;
          right: 48px;
        }

        h5 {
          font-size: 20px;
          padding-top: 42px;
        }
      }
    }
  }
  .wrapper {
    .event-header {
      cursor: pointer;
      height: 48px;
      padding: 0 24px;
      h4 {
        font-size: 24px;
      }

      svg {
        width: 21px;
        height: 12px;
      }
    }
  }

  .tournament-director,
  .general {
    padding: 40px 48px 52px 48px;
    border-radius: 0px;
    border-top: 0;

    h4 {
      font-size: 20px;
    }
    h6 {
      font-size: 16px;
      margin-top: 32px;
      margin-bottom: 0;
    }

    .default-inputfield {
      margin-top: 32px;
      width: 100%;
    }
  }
  .general {
    padding-bottom: 12px;
  }
  .details {
    > div {
      display: flex;
      flex-direction: row;
      padding: 40px 48px 52px 48px;
      border-radius: 0px;
    }

    span {
      h5 {
        font-size: 20px;
        margin-bottom: 20px;

        &.location {
          margin-top: 36px;
        }
      }
      .default-inputfield {
        width: 80%;
      }
      .set-location {
        width: 80%;
      }
      .find-location {
        width: 80%;
      }
    }
    span:first-of-type {
      width: 55%;
    }
    span:last-of-type {
      width: 45%;
    }

    .rounds {
      h5 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-right: 11px;
      }
    }
    .pools-selector {
      h5 {
        margin-top: 36px;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
      }
    }
  }
  main {
    > .wrapper {
      margin-top: 48px;
      &:first-of-type {
        margin-top: 100px;
      }
    }
  }

  .registration-type {
    padding: 40px 48px 52px 40px;
    border-radius: 0;
    margin-top: 0px;
    h5 {
      font-size: 20px;
      margin: 0;
      text-align: left;
    }
    .default-radio-buttons {
      margin-top: 32px;
    }
  }
  .pdga-tier {
    padding: 40px 40px 40px 40px;
    border-radius: 0px;
    transition: all ease 0.3s;

    &.expanded {
      padding-bottom: 52px;
      transition: all ease 0.3s;
    }

    h5 {
      font-size: 20px;
    }
    .radios {
      display: flex;
      width: 100%;
      .default-radio-buttons {
        width: 20%;
        margin-top: 32px;
        &:nth-child(even) {
          padding: 0;
        }
      }
    }
  }

  .registration-open {
    border-radius: 0;
    padding: 40px 48px 52px 40px;
    flex-direction: row;
    width: 100%;
    margin-top: 0;
    .left {
      width: 40%;
      border-right: 1px solid $fog;
      padding-right: 48px;
      h5 {
        margin: 0;
        text-align: left;
      }
    }
    .datepicker {
      margin-top: 24px;
    }

    .auto-open {
      width: 60%;
      padding-left: 48px;
      margin-top: 0;
      .header {
        h6 {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
      }
    }

    h5 {
      font-size: 20px;
    }
  }
  .misc-settings {
    padding: 0;
    display: flex;
    border-radius: 0;

    .max-players {
      width: 30%;
      padding-top: 42px;
      justify-content: center;
      .selector-container {
        margin: 0 0 52px 0;
      }

      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }
    }
    .group-size {
      width: 40%;
      border-right: 1px solid $fog;
      border-left: 1px solid $fog;
      border-top: none;
      padding: 0;
      margin: 0;
      justify-content: center;
      padding-top: 42px;
      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }

      .item {
        margin: 0 6px 52px 6px;
        height: 48px;
        width: 48px;
        font-size: 16px;
      }
    }
    .wildcards {
      width: 30%;
      border-top: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      padding-top: 42px;
      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }
      .selector-container {
        margin: 0 0 52px 0;
      }
    }
  }

  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;

    .bar {
      width: 100%;
    }
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 2px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
}
</style>