<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g class="a">
      <rect class="d" width="18" height="18" rx="4" />
      <rect class="e" x="1" y="1" width="16" height="16" rx="3" />
    </g>
    <g transform="translate(-985 -4282)">
      <rect
        class="b"
        width="18"
        height="18"
        rx="4"
        transform="translate(991 4288)"
      />
      <g transform="translate(130 -13)">
        <path
          class="c"
          d="M21388.973,3034.836h10"
          transform="translate(-20523.973 1275.164)"
        />
        <path
          class="c"
          d="M0,0H10"
          transform="translate(870 4315) rotate(-90)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Duplicate",
};
</script>

<style lang="scss" scoped>
.a,
.c,
.e {
  fill: none;
}
.a {
  stroke: #b40068;
  stroke-linecap: round;
}
.a,
.c {
  stroke-width: 2px;
}
.b {
  fill: #b40068;
}
.c {
  stroke: #fff;
}
.d {
  stroke: none;
}
</style>